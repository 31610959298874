import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {fetchDataAndProceedWithToolkit} from "../helper"
import { GET_FORM_DATA, FORM_SUBMIT } from "./api"

const formSlice = createSlice({
  name: "form",
  initialState: {
    formData: {},
    waMeUrl: "",
    sessionId: "",
    uniqueId: "",
  },
  reducers: {
    setFormData(state, action) {
      state.formData = action.payload
    },
    setSessionId(state, action) {
      state.sessionId = action.payload
    },
    setUniqueId(state, action) {
      state.uniqueId = action.payload
    },
    // setWaMeUrl(state, action) {
    //   state.waMeUrl = action.payload
    // },
  },
})

export const getFormData = createAsyncThunk(
  "billing/getPlanGuideSteps",
  async (data = {}, helpers)  => 
    fetchDataAndProceedWithToolkit({
      url: GET_FORM_DATA,
      data,
    },
    helpers
  ),
)

export const formDataSubmit = createAsyncThunk(
  "form/formDataSubmit",
  async (data={}, helpers) =>
    fetchDataAndProceedWithToolkit({
      url : FORM_SUBMIT,
      data,
      method: "post"
    },
    helpers
  )
)


export const { setFormData, setSessionId, setUniqueId, setWaMeUrl } =
  formSlice.actions
export default formSlice.reducer
