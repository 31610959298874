import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Typography,
  Card,
  Row,
  Col,
  Tooltip,
  Select,
  Button,
  Input,
  InputNumber,
  DatePicker,
  message,
} from "antd"
import { EditOutlined } from "@ant-design/icons"

import moment from "moment"

import IMG_FORM_SUBMIT from "../data/assets/FORM_SUBMIT_IMG.png"

import {
  setFormData,
  setSessionId,
  setUniqueId,
  formDataSubmit,
} from "../data/store/formSlice"
import { getFormDetails } from "../data/store/formSlice/api"

import { getURLSearchParams } from "../data/common"
import DynamicForm from "./component/DynamicForm/DynamicForm"

import ShowMessage from "./component/ShowMessage"
import ShowSuccess from "./component/ShowSuccess"
import HandleFinalSubmit from "./component/FinalSubmit"
import SpinnerLoader from "./component/SpinnerLoader"

import "../data/styles/app.scss"
import "../data/styles/common.scss"

const { Title, Text } = Typography

const AppDisplay = () => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.form.formData || {})
  const [submitLoading, setFormLoading] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [showLoader, setShowLoader] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({})
  const { TextArea } = Input

 const [payload, setPayload] = useState(null)

  const [editSectionIndex, setEditSectionIndex] = useState(null)
  const [backupPayload, setBackupPayload] = useState(null)
  const [fetchError, setFetchError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [allValid, setAllValid] = useState(true)

  useEffect(() => {
    const urlParams = getURLSearchParams(window.location.href)

    const sessionId = urlParams.get("sessionId")
    const uniqueId = urlParams.get("uniqueId")
    console.log(sessionId, uniqueId, "params")

    if (sessionId) {
      dispatch(setSessionId(sessionId))
    }
    if (uniqueId) {
      dispatch(setUniqueId(uniqueId))
    }
  }, [dispatch])

  useEffect(() => {
    const res = localStorage.getItem("savedFormData")
    if (res) {
      const savedData = JSON.parse(res)
      setPayload((prevPayload) => {
        if (
          !prevPayload ||
          !prevPayload.customFormData ||
          !prevPayload.customFormData.formData
        ) {
          return prevPayload
        }

        const updatedFormData = prevPayload.customFormData.formData.map(
          (section) => {
            section.data = section.data.map((field) => {
              if (savedData[field.props.name] !== undefined) {
                field.props.value = savedData[field.props.name]
              }
              return field
            })
            return section
          }
        )

        return {
          ...prevPayload,
          formData: {
            ...prevPayload.customFormData,
            formData: updatedFormData,
          },
        }
      })
    }
  }, [])

  const applyDependencyLogic = (field, formData) => {
    const { dependentField, props } = field

    if (dependentField && dependentField.length > 0) {
      const conditionsMet = dependentField.every((condition) => {
        const [dependentName, requiredValue] = condition.split(":")
        const currentValue =
          formData[dependentName]?.props?.value ||
          formData[dependentName]?.props?.defaultValue
        return currentValue === requiredValue
      })

      const filteredOptions = props.options.filter(
        (option) =>
          !option.parent || formData?.country?.props?.value === option.parent
      )

      return {
        ...field,
        isVisible: conditionsMet,
        props: {
          ...props,
          options: filteredOptions,
        },
      }
    }

    return { ...field, isVisible: true }
  }

  const fetchPayload = async () => {
    setLoading(true)
    try {
      const response = await getFormDetails()
      setPayload(response)
      setFetchError(false)
    } catch (error) {
      setFetchError(true)
    } finally {
       setTimeout(() => setLoading(false), 1500)
    }
  }

  useEffect(() => {
    fetchPayload()
     const loaderTimeout = setTimeout(() => setShowLoader(false), 1500)
     return () => clearTimeout(loaderTimeout)
  }, [])

    if (showLoader) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SpinnerLoader />
        </div>
      )
    }

  if (fetchError) {
    return (
      <div
        className="ori-flex ori-flex-column ori-flex-center"
        style={{ marginTop: "50px" }}
      >
        <img
          src="path_to_image_or_use_illustration_component"
          alt="Error Illustration"
          className="ori-img-contain"
        />
        <h2 style={{ marginTop: "20px", color: "#555" }}>
          Something went wrong
        </h2>
        <p style={{ color: "#888" }}>
          Please try again or click the button below to fetch the data.
        </p>
        <button
          onClick={fetchPayload}
          className="ori-btn"
          style={{
            marginTop: "20px",
            backgroundColor: "#1890ff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Retry
        </button>
      </div>
    )
  }

  if (
    !payload ||
    !payload.customFormData ||
    !payload.customFormData.formData ||
    payload.customFormData.formData.length === 0 ||
    payload.defaultMessage ||
    !payload.isSuccess
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: "100vh",
        }}
      >
        <ShowMessage
          src={IMG_FORM_SUBMIT}
          message={payload?.defaultMessage}
          height={400}
          width={400}
          btn_text="Go Back to Whatsappp"
          btn_props={{
            onClick: () => {
              window.open(payload.waMeUrl, "_blank")
            },
          }}
        />
      </div>
    )
  }

  const handleEditClick = (sectionIdx) => {
    setEditSectionIndex(sectionIdx)
    setBackupPayload(JSON.parse(JSON.stringify(payload)))
  }

  const handleFieldChange = (sectionIdx, fieldName, value) => {
    const updatedSections = [...payload.customFormData.formData]
    const section = updatedSections[sectionIdx]

    if (section?.data) {
      const fieldIndex = section.data.findIndex(
        (field) => field.props.name === fieldName
      )

      if (fieldIndex !== -1) {
        section.data[fieldIndex].props.value = value
        const field = section.data[fieldIndex]
        const regex = field.props.regex ? new RegExp(field.props.regex) : null
        if (regex && !regex.test(value)) {
          setFieldErrors((prev) => ({
            ...prev,
            [fieldName]: field.props.regexMessage || 'Invalid format',
          }))
        } else {
          setFieldErrors((prev) => {
            const updatedErrors = { ...prev }
            delete updatedErrors[fieldName]
            return updatedErrors
          })
        }

        section.data = section.data.map((field) => {
          if (
            field.dependentField?.some((condition) =>
              condition.startsWith(`${fieldName}:`)
            )
          ) {
            return applyDependencyLogic(
              field,
              section.data.reduce((acc, f) => {
                acc[f.props.name] = f
                return acc
              }, {})
            )
          }
          return field
        })

        setPayload({
          ...payload,
          customFormData: {
            ...payload.customFormData,
            formData: updatedSections,
          },
        })
      }
    }
  }

  const validateTotalSum = (section) => {
    if (!section.totalLimit) return true

    const totalSum = section.data.reduce((sum, field) => {
      if (field.props.value && !isNaN(field.props.value)) {
        return sum + parseFloat(field.props.value)
      }
      return sum
    }, 0)

    return totalSum <= section.totalLimit
  }

  const handleCancelClick = () => {
    if (backupPayload) {
      setPayload(backupPayload)
      setBackupPayload(null)
    }
    setEditSectionIndex(null)
  }

 const validateRequiredFields = (sectionIdx) => {
   const section = payload.customFormData?.formData[sectionIdx]
   if (!section) return true

   let isValid = true

   section.data.forEach((field) => {
     const fieldValue =
       field.props.value ||
       (typeof field?.props?.defaultValue === 'object'
         ? field?.props?.defaultValue.label
         : field?.props?.defaultValue)

     // Check if the field is required and has a value
     if (field.props.required && !fieldValue) {
       console.error(`Validation failed for required field: ${field.label}`)
       isValid = false
     }

     if (field.props.regex && fieldValue) {
       const regex = new RegExp(field.props.regex)
       if (!regex.test(fieldValue)) {
         console.error(
           `Validation failed for field: ${field.label}. ${field.props.regexMessage}`
         )
         isValid = false
       }
     }
   })

   return isValid
 }

const handleSaveClick = () => {
  let allValid = true

  payload.customFormData.formData.forEach((section, sectionIdx) => {
    if (!validateRequiredFields(sectionIdx)) {
      allValid = false 
    }
  })

  const totalValid = payload.customFormData.formData.every((section) =>
    validateTotalSum(section)
  )

  const savedData = {}
  payload.customFormData.formData.forEach((section) => {
    section.data.forEach((field) => {
      savedData[field.props.name] = field.props.value
    })
  })
  if (allValid && totalValid) {
    localStorage.setItem('savedFormData', JSON.stringify(savedData))
    message.success('Data saved successfully!')
    setEditSectionIndex(null)
    setBackupPayload(null)
  } else {
    message.error('Validation failed. Please check the fields.')
  }
}

  const renderEditableField = (field, value, onChange) => {
    const errorMessage = fieldErrors[field.props.name]
    const isError = errorMessage !== undefined
    const fieldValue = value || field?.props?.value || ''

    const inputProps = {
      value: fieldValue,
      onChange: (e) => onChange(e.target.value),
      placeholder: field.props?.placeholder || 'Enter text',
      disabled: field.props?.disable,
      style: {
        borderColor: isError ? 'red' : '',
      },
    }

    const errorMsgProps = {
      style: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
      },
    }

    switch (field.type) {
      case 'date':
        return (
          <div>
            <DatePicker
              value={
                value ? moment(value, field.props.format || 'YYYY-MM-DD') : null
              }
              onChange={(date, dateString) => onChange(dateString)}
              format={field.props.format || 'YYYY-MM-DD'}
              placeholder={field.props.placeholder || 'Select a date'}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'select':
        return (
          <div>
            <Select
              value={value}
              onChange={onChange}
              showSearch
              placeholder={field.props.placeholder || 'Select an option'}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={field.props.disable}
            >
              {field.props.options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'text':
        return (
          <div>
            <Input
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={field.props.placeholder || 'Enter text'}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'textarea':
        return (
          <div>
            <TextArea
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={field.props.placeholder || 'Enter text'}
              rows={field.props.rows || 4}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'inputNumber':
        return (
          <div>
            <InputNumber
              value={value}
              min={field.props.min}
              max={field.props.max}
              formatter={field.props.formatter}
              parser={field.props.parser}
              onChange={(value) => onChange(value)}
              style={{ width: '100%' }}
              disabled={field.props.disable}
            />
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'checkbox':
        return (
          <div>
            {field.props.options.map((option) => (
              <label key={option.value}>
                <input
                  type='checkbox'
                  checked={value?.includes(option.value)}
                  onChange={(e) => {
                    const newValue = e.target.checked
                      ? [...(value || []), option.value]
                      : value.filter((val) => val !== option.value)
                    onChange(newValue)
                  }}
                  disabled={field.props.disable}
                />
                {option.label}
              </label>
            ))}
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      case 'radio':
        return (
          <div>
            {field.props.options.map((option) => (
              <label key={option.value}>
                <input
                  type='radio'
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                  disabled={field.props.disable}
                />
                {option.label}
              </label>
            ))}
            {isError && <div {...errorMsgProps}>{errorMessage}</div>}
          </div>
        )
      default:
        return <Text>{value || 'N/A'}</Text>
    }
  }

  const renderDisplaySection = (section, sectionIdx) => {
    const isEditing = editSectionIndex === sectionIdx

    let totalValid = true
    if (section.totalLimit) {
      const total = section.data.reduce((sum, field) => {
        if (field.type === 'inputNumber') {
          sum += field.props.value || 0
        }
        return sum
      }, 0)

      totalValid = total <= section.totalLimit
    }

    const isValid = validateRequiredFields(sectionIdx) && totalValid

    return (
      <Card
        key={sectionIdx}
        title={
          <Tooltip title={section.Description}>{section.sectionName}</Tooltip>
        }
        extra={
          section.isEdit && !isEditing ? (
            <EditOutlined
              onClick={() => handleEditClick(sectionIdx)}
              style={{ cursor: 'pointer', fontSize: '18px', color: '#1890ff' }}
            />
          ) : null
        }
        className='section-card'
      >
        <Row gutter={[16, 16]}>
          {section.data.map((field, idx) => {
            const fieldValue =
              field.props.value ||
              (typeof field?.props?.defaultValue === 'object'
                ? field?.props?.defaultValue.label
                : field.props.value === ''
                ? field.props.value
                : field?.props?.defaultValue) ||
              formData?.formData?.[field.props.name]
            return (
              <Col span={12} key={idx}>
                <div className='field-display'>
                  {isEditing && field.props.required && (
                    <Text
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        marginLeft: '4px',
                      }}
                    >
                      *
                    </Text>
                  )}
                  <Text strong>{field.label}:</Text>

                  {isEditing ? (
                    renderEditableField(field, fieldValue, (value) =>
                      handleFieldChange(sectionIdx, field.props.name, value)
                    )
                  ) : (
                    <Text>
                      {Array.isArray(fieldValue)
                        ? fieldValue
                            .map((val) => {
                              const option = field.props.options.find(
                                (option) => option.value === val
                              )
                              return option?.label || val
                            })
                            .join(', ') || 'N/A'
                        : fieldValue || 'N/A'}
                    </Text>
                  )}
                </div>
              </Col>
            )
          })}
        </Row>

        {isEditing && (
          <div className='button-group'>
            <Button
              type='primary'
              onClick={handleSaveClick}
              style={{ marginRight: 10 }}
              disabled={!isValid || !allValid}
            >
              Save
            </Button>
            <Button onClick={handleCancelClick}>Cancel</Button>
          </div>
        )}
      </Card>
    )
  }
  return (
    <>
      <div className='app-container'>
        <Title level={2} className='dynamic-form-title'>
          {payload.customFormData.displayType === 'form'
            ? payload.customFormData.header || 'Form'
            : payload.customFormData.header || 'View Submitted Data'}
        </Title>

        {payload.customFormData.displayType === 'form' ? (
          <DynamicForm
            payload={{
              sections: payload.customFormData.formData.map((section) => ({
                title: section.sectionName,
                description: section.description,
                totalLimit: section.totalLimit,
                fields: section.data,
              })),
              waMeUrl: payload.waMeUrl,
            }}
            initialData={formData}
            onSubmit={(data) => {
              console.log('Submitted Data:', data)
              dispatch(setFormData(data))
            }}
          />
        ) : (
          payload.customFormData?.formData.map((section, idx) =>
            renderDisplaySection(section, idx)
          )
        )}

        {payload.customFormData.displayType !== 'form' && (
          <HandleFinalSubmit payload={payload} formData={formData} />
        )}
      </div>

      {showSuccessMessage && <ShowSuccess message={defaultMessage} />}
    </>
  )
}
export default AppDisplay
