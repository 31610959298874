// ./data/store/index.js

import { configureStore, combineReducers } from '@reduxjs/toolkit'
import formReducer from './formSlice'

const rootReducer = combineReducers({
  form: formReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})
