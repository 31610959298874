import React from "react"
import { Button, Col, theme as themeConfig } from "antd"

const ShowMessage = ({
  title,
  message,
  children,
  ImgComponent,
  height,
  width,
  src,
  btn_props,
  btn_text,
}) => {
  const { token } = themeConfig.useToken()
  return (
    <Col
      className="ori-flex ori-flex-column ori-flex-center ori-full-width ori-full-parent-height"
      style={{
        flexWrap: "wrap",
      }}
    >
      {ImgComponent ? (
        <Col>
          <ImgComponent
            height={height}
            width={width}
            color={token.colorPrimary}
          />
        </Col>
      ) : src ? (
        <img src={src} height={height} width={width} alt="not found" />
      ) : null}
      {title && (
        <Col>
          <p
            style={{
              textAlign: "center",
              fontWeight: token.fontWeightStrong,
              fontSize: token.fontSizeHeading3,
              marginBottom: token.marginXXS,
              color: token.colorPrimary,
            }}
          >
            {title}
          </p>
        </Col>
      )}
      {message && (
        <Col>
          <p
            style={{
              textAlign: "center",
              fontSize: token.fontSizeHeading4,
              fontWeight: token.fontWeightStrong,
              color: token.colorTextDescription,
            }}
          >
            {message}
          </p>
        </Col>
      )}
      {btn_props && (
        <div
          className="ori-flex-row ori-flex-jc "
          style={{
            paddingTop: token.paddingSM,
            paddingBottom: token.paddingSM,
            width: "100%",
          }}
        >
          <Button {...btn_props}>{btn_text}</Button>
        </div>
      )}
      <Col>{children}</Col>
    </Col>
  )
}

ShowMessage.defaultProps = {
  title: "",
  message: "Result not found",
  children: null,
}

export default ShowMessage
