import { checkDevice } from "../../common"

const initialState = {
  deviceData: checkDevice.deviceStatus(),
  pageLoading: false,
  pageLoaderText: "",
  modalLoading: false,
  authLoading: false,
}

export default initialState