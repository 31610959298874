import { getURLSearchParams } from "../../common"
const API_URL = `${process.env.REACT_APP_BASE_URL}/brand/indiafirst-iva/dynamicform/getformdetails`


export const getFormDetails = async () => {
  const urlParams = getURLSearchParams(window.location.href)
    
    const sessionId = urlParams.get('sessionId')
    const uniqueId = urlParams.get('uniqueId')
   try {
     let response

     if (sessionId && uniqueId) {
       response = await fetch(
         `${API_URL}/?sessionId=${sessionId}&uniqueId=${uniqueId}`,
         {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
           },
         }
       )
     } else {
       response = await fetch(`${API_URL}/?isTest=true`, {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
         },
       })
     }

     console.log(response, 'data')

     if (!response.ok) {
       throw new Error(`Error: ${response.statusText}`)
     }

     return await response.json()
   } catch (error) {
     console.error('Error fetching form details:', error)
     throw error
   }
  // return Promise.reject(new Error('Simulated API failure'))
}

export const GET_FORM_DATA="/getFormData"
export const FORM_SUBMIT = "/dynamicform/submitformdetails"
