import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

const { Title, Text } = Typography

const ShowSuccess = ({ message }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#fff',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 9999,
        textAlign: 'center',
      }}
    >
      <CheckCircleOutlined
        style={{ fontSize: 50, color: '#52c41a', marginBottom: 20 }}
      />
      <Title level={2} style={{ color: '#000' }}>
        Submission Successful
      </Title>
      <Text style={{ color: '#000', marginBottom: 20 }}>{message}</Text>
    </div>
  )
}

export default ShowSuccess
