import { createSlice } from "@reduxjs/toolkit"
import initialState from "./initialState"

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    updatePageState(state, action) {
      return { ...state, ...action.payload }
    },
  },
})

export const { updatePageState } = pageSlice.actions
export default pageSlice.reducer
