import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Radio,
  Checkbox,
  Select,
  InputNumber,
} from 'antd'
import moment from 'moment'
import { setFormData } from '../../../data/store/formSlice/index'
import '../../../data/styles/dynamicForm.scss'
import ShowSuccess from '../ShowSuccess'

const DynamicForm = ({ payload, onSubmit, initialData = {} }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.form.formData)
  const [filteredOptions, setFilteredOptions] = useState({})
  const [fundAllocation, setFundAllocation] = useState({})
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [defaultMessage, setDefaultMessage] = useState('')

useEffect(() => {
  const initializeOptions = () => {
    const initialValues = { ...(initialData || formData || {}) }

    if (Array.isArray(payload?.sections)) {
      payload.sections.forEach((section) => {
        if (Array.isArray(section.fields)) {
          section.fields.forEach((field) => {
            if (field.props?.defaultValue) {
              initialValues[field.props.name] = field.props.defaultValue
            }
          })
        }
      })
    }

    form.setFieldsValue(initialValues)
  }

  initializeOptions()
}, [payload, initialData, formData, form])


  const filterOptionsByDependencies = (options, dependencies, values) => {
    if (!Array.isArray(dependencies) || !Array.isArray(options)) return options

    return options.filter((option) =>
      dependencies.some((dependency) => {
        const [fieldName, expectedValue] = dependency.split(':')
        return (
          values[fieldName] === expectedValue && option.parent === expectedValue
        )
      })
    )
  }

const handleValuesChange = (changedValues, allValues) => {

  const updatedOptions = {}
  const resetFields = []

  if (Array.isArray(payload?.sections)) {
    payload.sections.forEach((section) => {
      if (Array.isArray(section.fields)) {
        section.fields.forEach((field) => {
          if (Array.isArray(field.props?.options) && field.dependentField) {
            const filtered = filterOptionsByDependencies(
              field.props.options,
              field.dependentField,
              allValues
            )
            updatedOptions[field.props.name] = filtered

            if (
              !filtered.some(
                (option) => option.value === allValues[field.props.name]
              )
            ) {
              resetFields.push(field.props.name)
            }
          }
        })
      }
    })
  }

  resetFields.forEach((fieldName) => form.resetFields([fieldName]))
  setFilteredOptions(updatedOptions)

  localStorage.setItem('unsavedFormData', JSON.stringify(allValues))
}

  const handleFundAllocationChange = (fieldName, value) => {
    const updatedAllocation = { ...fundAllocation, [fieldName]: value || 0 }

    const totalPercentage = Object.values(updatedAllocation).reduce(
      (acc, val) => acc + val,
      0
    )

    if (totalPercentage > 100) {
      form.setFields([
        {
          name: fieldName,
          errors: [`Total allocation exceeds the 100% limit`],
        },
      ])
    } else {
      form.setFields([
        {
          name: fieldName,
          errors: [],
        },
      ])
    }

    setFundAllocation(updatedAllocation)
    form.setFieldsValue({ [fieldName]: updatedAllocation[fieldName] })
  }
const handleFinish = async () => {
  try {
    const values = await form.validateFields()
    const formattedValues = { ...values }

    if (Array.isArray(payload?.sections)) {
      payload.sections.forEach((section) => {
        if (Array.isArray(section.fields)) {
          section.fields.forEach((field) => {
            if (field.type === 'date' && formattedValues[field.props.name]) {
              const dateValue = formattedValues[field.props.name]
             if (moment.isMoment(dateValue) && dateValue.isValid()) {
               formattedValues[field.props.name] =
                 dateValue.format('YYYY-MM-DD')
             }
            }
          })
        }
      })
    }
    dispatch(setFormData(formattedValues))
    localStorage.setItem('savedFormData', JSON.stringify(formattedValues))

    const dataToSend = {
      sessionId: new URLSearchParams(window.location.search).get('sessionId'),
      uniqueId: new URLSearchParams(window.location.search).get('uniqueId'),
      formData: formattedValues,
    }

    const API_URL =
      `${process.env.REACT_APP_BASE_URL}/brand/indiafirst-iva/dynamicform/submitformdetails`

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()

      setDefaultMessage(
        responseData.defaultMessage ||
          'Your data has been submitted successfully!'
      )
      setShowSuccessMessage(true)

      if (payload.waMeUrl) {
        setTimeout(() => {
          window.open(payload.waMeUrl, '_blank')
        }, 2000)
      }
    } catch (error) {
      console.error('Error while submitting data to API:', error)
      setDefaultMessage('Submission failed. Please try again.')
      setShowSuccessMessage(true)
    }

    form.resetFields()
    localStorage.removeItem('unsavedFormData')
  } catch (error) {
    console.error('Validation failed:', error)
  }
}

const renderField = (field) => {
  const { type, props } = field

  const getValidationRules = (field) => {
    const rules = []
    if (field.props.required) {
      rules.push({ required: true, message: `${field.label} is required` })
    }
    if (field.props.regex) {
      const regex = new RegExp(field.props.regex)
      rules.push({
        validator: (_, value) => {
          if (!value || regex.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(field.props.regexMessage || 'Invalid input')
        },
      })
    }

    return rules
  }

  const renderInputField = () => {
    switch (type) {
      case 'text':
        return <Input {...props} value={form.getFieldValue(props.name)} />
      case 'textarea':
        return (
          <Input.TextArea {...props} value={form.getFieldValue(props.name)} />
        )
      case 'date':
        return (
          <DatePicker
            {...props}
            value={
              form.getFieldValue(props.name)
                ? moment(form.getFieldValue(props.name))
                : moment()
            }
            onChange={(date) => {
              form.setFieldsValue({ [props.name]: date })
            }}
          />
        )

      case 'radio':
        return (
          <Radio.Group {...props} value={form.getFieldValue(props.name)}>
            {Array.isArray(props?.options) &&
              props.options.map((option, idx) => (
                <Radio key={idx} value={option.value}>
                  {option.label}
                </Radio>
              ))}
          </Radio.Group>
        )
      case 'checkbox':
        return (
          <Checkbox.Group {...props} value={form.getFieldValue(props.name)}>
            {Array.isArray(props?.options) &&
              props.options.map((option, idx) => (
                <Checkbox key={idx} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
          </Checkbox.Group>
        )
      case 'select':
        return (
          <Select {...props} value={form.getFieldValue(props.name)} showSearch>
            {Array.isArray(filteredOptions[props.name]) &&
              filteredOptions[props.name].map((option, idx) => (
                <Select.Option
                  key={idx}
                  value={option.value}
                  label={option.label}
                >
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        )
      case 'inputNumber':
        return (
          <InputNumber
            {...props}
            value={form.getFieldValue(props.name) || fundAllocation[props.name]}
            onChange={(value) => handleFundAllocationChange(props.name, value)}
            min={0}
            max={payload.totalLimit}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
          />
        )
      default:
        return null
    }
  }

  return (
    <Form.Item
      name={props.name}
      label={field.label}
      rules={getValidationRules(field)}
    >
      {renderInputField()}
    </Form.Item>
  )
}

  return (
    <div className='dynamic-form-container'>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
        className='dynamic-form'
      >
        {Array.isArray(payload?.sections) &&
          payload.sections.map((section, sectionIdx) => (
            <Card
              key={sectionIdx}
              title={section.title}
              className='custom-card'
            >
              {section.description && <p>{section.description}</p>}
              <Row gutter={[16, 16]}>
                {Array.isArray(section.fields) &&
                  section.fields.map((field, idx) => (
                    <Col span={12} key={idx}>
                      {renderField(field)}
                    </Col>
                  ))}
              </Row>
            </Card>
          ))}
        <Row justify='center'>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
            className='custom-save-button'
          >
            Final Submit
          </Button>
        </Row>
      </Form>
      {showSuccessMessage && <ShowSuccess message={defaultMessage} />}
    </div>
  )
}

export default DynamicForm
